import React, { useState } from "react";
import "../css/cadastro.css";

function Cadastro() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  console.log("Nome:", nome);
  console.log("Email:", email);
  console.log("Senha:", senha);

  return (
    <div className="componenteCadastro">
      <div className="cadastro">
        <h1>Cadastro</h1>
        <div className="formulario">
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Informe seu nome de usuário"
            required
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Informe seu email"
            required
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Informe sua senha"
            required
            onChange={(e) => {
              setSenha(e.target.value);
            }}
          />
          <input type="submit" value="Cadastrar" />
        </div>
      </div>
    </div>
  );
}

export default Cadastro;
