import {useState} from "react"
import { Link } from "react-router-dom";
import axios from "axios";
import "../css/login.css";
console.log("Versão 1.2");

function Login() {
  const [usuarioEmail, setUsuarioEmail] = useState('')
  const [usuarioSenha, setUsuarioSenha] = useState('')

  const URL = 'https://meustreinos.online/usuariologin';

  function enviarDados(){
    const usuarioDados = {
      email: usuarioEmail,
      senha: usuarioSenha
    }

axios.get('https://meustreinos.online/teste')
    .then(resposta => {
        console.log(resposta);
    })
    .catch(error => {
        console.error(error);
    });	

    axios.post(URL, usuarioDados)
    .then(response => {
      if(response.data){
        console.table("Login realizado:", response.data)
      } else console.log("Usuário não encontrado", response.data)
    }).catch(erro => {
      console.error("Login erro:", erro)
    })
  }

return (
    <div className="componenteLogin">
      <div className="cabecalho">
        <h1>Meus Treinos</h1>
        <h2>Bem-vindo a Meus Treinos</h2>
        <p>
          Aqui você pode compartilhar seus treinos, registrar eles, ver a sua
          evolução ao longo do tempo, além de ver os treinos dos seus amigos ou
          influencers favoritos e você também pode ver os exercícios para o
          músculo que você escolher, além de ter fichas de treinos para todos os
          gostos.
        </p>
      </div>
      <div className="caixaLogin">
        <h2>Login</h2>
        <div>
          <input
            placeholder="Informe o seu E-mail"
            type="text"
            id="username"
            name="username"
            required
            onChange={(e) => setUsuarioEmail(e.target.value)}
          />
        </div>

        <div className="login">
          <input
            placeholder="Informe sua senha"
            type="password"
            id="pass"
            name="password"
            required
            onChange={(e) => setUsuarioSenha(e.target.value)}
          />
        </div>
        <button type="button" onClick={enviarDados}>Entrar</button>
        <Link id="linkCadastro" to="/cadastro">
          Cadastro
        </Link>
      </div>
    </div>
  );
}

export default Login;
